// @flow strict
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Content from '../components/content'
import Layout from '../components/layout'
import ImageContainer from '../components/imageContainer'
import Paragraph from '../components/paragraph'

type Props = {
  location: {
    pathname: string,
  },
  data: {
    profileImg: {
      childImageSharp: {
        fluid: string,
      },
    },
  },
}

export default ({ data, ...props }: Props) => (
  <Layout {...props}>
    <Content direction="column">
      <h1>About Us</h1>
      <ImageContainer as="figure">
        <Img fluid={data.profileImg.childImageSharp.fluid} />
        <figcaption>
          <h4 style={{ textAlign: 'center' }}>
            Seyi Sowemimo, SAN <br /> Managing Partner
          </h4>
        </figcaption>
      </ImageContainer>
      <Paragraph width="50%">
        After successfully completing his law degree at the University of Lagos.
        Seyi Sowemimo went on to undertake a Masters at the London School of
        Economics (LSE). Having completed this he began his career over 30 years
        ago and has since become a senior advocate of Nigeria (SAN).
      </Paragraph>
    </Content>
  </Layout>
)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    profileImg: file(
      relativePath: { eq: "images/seyi_sowemimo_grey_background.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
